import React from "react"
import { graphql } from "gatsby"
import {Header} from "../components/header"
import Img from "gatsby-image"
import AboutBammer from "../components/about-bammer"
import '../styles/about.css'

import { getImage, GatsbyImage } from "gatsby-plugin-image"
import { convertToBgImage } from "gbimage-bridge"
import BackgroundImage from 'gatsby-background-image'

export default function Template({
    data, // this prop will be injected by the GraphQL query below.
}) {

    const { markdownRemark, placeholderImage } = data // data.markdownRemark holds your post data
    const { frontmatter, html } = markdownRemark

    let featuredImgFluid = markdownRemark.frontmatter.featuredImage.childImageSharp.fluid

    const image = getImage(placeholderImage)

    // Use like this:
    const bgImage = convertToBgImage(image)

    return (
        <div style={{background: '#0B2545'}}>
            <Header />
            <div style={{ display: "grid", boxSizing: 'border-box' }}>
                <div style={{gridArea: "1/1",
                    position: "relative",
                    display: "grid",
                    }}>
                    <BackgroundImage
                        Tag="section"
                        fluid={image}
                        style={{backgroundRepeat: 'repeat', position: 'absolute', width: '100%', height: '100%'}}
                    >
                        <GatsbyImage image={image} alt={"testimage"}
                        style={{backgroundRepeat: 'repeat', position: 'absolute', width: '100%', height: '100%'}} />
                    </BackgroundImage>
                </div>
                <div
                    style={{
                    gridArea: "1/1",
                    position: "relative",
                    placeItems: "center",
                    display: "grid",
                    }}
                >
                    <div style={{height: '100%', width: '65%', backgroundColor: '#0B2545'}}>
                        <main>
                            <AboutBammer></AboutBammer>
                            <div style={{height: '5rem'}}></div>
                            <h1 style={{color: "#F3EFE0"}}>{frontmatter.author}</h1>
                            <h2 style={{color: "#F3EFE0"}}>{frontmatter.role}</h2>
                            <div className="avatar">
                                <Img style={{display: 'flex', alignItems: 'center', justifyContent: 'center'}} fluid={featuredImgFluid} />
                            </div>
                            <div
                                style={{color: "#F3EFE0", marginTop: '2rem'}}
                                dangerouslySetInnerHTML={{ __html: html }}
                            />
                        </main>
                    </div>
                    
                </div>
            </div>
        </div>
    )
}

export const pageQuery = graphql`
  query($id: String!) {
    markdownRemark(id: { eq: $id }) {
        html
        frontmatter {
            date(formatString: "MMMM DD, YYYY")
            slug
            title
            author
            role
            featuredImage {
                childImageSharp {
                    fluid(maxWidth: 150, maxHeight: 150) {
                    ...GatsbyImageSharpFluid_noBase64
                    ...GatsbyImageSharpFluidLimitPresentationSize
                    }
                }
            }
        }   
    },
    placeholderImage: file(relativePath: { eq: "background2.png" }) {
        childImageSharp {
        gatsbyImageData(
            width: 4096
            height: 4096
            placeholder: BLURRED
        )
        }
    }
  }`