import * as React from "react"
import { Link } from "gatsby"
import { StaticImage } from "gatsby-plugin-image"
import { Grid } from "@mui/material"

const AboutBammer = () => {

  return (
    <div style={{ marginTop: "10rem", marginLeft: "10%", marginRight: "10%", height: "10%"}}>
        <h1 style={{color: "#F3EFE0"}}>The Bammer</h1>
        <h3 style={{color: "#F3EFE0"}}>Since its inception as Strange Memers in 2018 and from its rebrand in 2020, The Bammer has been a community for those that enjoy video games and friendly banter. We appreciate content in all its forms - from memes, to music recommendations, hot takes on (e)sports or science discussions.
        We support creators and players of all races, genders, sexualities and backgrounds. Join The Bammer!
        </h3>
        <Grid container>
            <Grid item xs={12} style={{display: 'flex', alignItems: 'center', justifyContent: 'center'}}>
                <Link to="/about/nick-j">
                    <StaticImage src="../images/avatars/nick_j.jpg" style={{marginTop: '2rem', height: '50px', width: '50px', 'borderRadius': '50%'}} height={50} />
                </Link>
            </Grid>
            <Grid item xs={6} style={{display: 'flex', alignItems: 'center', justifyContent: 'right', paddingRight: '10%'}}>
                <Link to="/about/rachel">
                    <StaticImage src="../images/avatars/rachel.png" style={{marginTop: '2rem', height: '50px', width: '50px', 'borderRadius': '50%'}} height={50} />
                </Link>
            </Grid>
            <Grid item xs={6} style={{display: 'flex', alignItems: 'center', justifyContent: 'left', paddingLeft: '10%'}}>
                <Link to="/about/korrey">
                    <StaticImage src="../images/avatars/korrey.jpg" style={{marginTop: '2rem', height: '50px', width: '50px', 'borderRadius': '50%'}} height={50} />
                </Link>
            </Grid>
            <Grid item xs={4} style={{display: 'flex', alignItems: 'center', justifyContent: 'right', paddingRight: '5%'}}>
                <Link to="/about/konnor">
                    <StaticImage src="../images/avatars/konnor.jpg" style={{marginTop: '2rem', height: '50px', width: '50px', 'borderRadius': '50%'}} height={50} />
                </Link>
            </Grid>
            <Grid item xs={4} style={{display: 'flex', alignItems: 'center', justifyContent: 'center'}}>
                <Link to="/about/nich">
                    <StaticImage src="../images/avatars/nich.png" style={{marginTop: '2rem', height: '50px', width: '50px', 'borderRadius': '50%'}} aspectRatio={2/1} height={50} />
                </Link>
            </Grid>
            <Grid item xs={4} style={{display: 'flex', alignItems: 'center', justifyContent: 'left', paddingLeft: '5%'}}>
                <Link to="/about/emily">
                    <StaticImage src="../images/avatars/emily.jpg" style={{marginTop: '2rem', height: '50px', width: '50px', 'borderRadius': '50%'}} aspectRatio={1.2} height={50} />
                </Link>
            </Grid>
            <Grid item xs={3} style={{display: 'flex', alignItems: 'center', justifyContent: 'center'}}>
                <Link to="/about/alex">
                    <StaticImage src="../images/avatars/alex.png" style={{marginTop: '4rem', height: '50px', width: '50px', 'borderRadius': '50%'}} height={50} />
                </Link>
            </Grid>
            <Grid item xs={3} style={{display: 'flex', alignItems: 'center', justifyContent: 'center'}}>
                <Link to="/about/justin">
                    <StaticImage src="../images/avatars/justin.jpg" style={{marginTop: '4rem', height: '50px', width: '50px', 'borderRadius': '50%'}} aspectRatio={2/1} height={50} />
                </Link>
            </Grid>
            <Grid item xs={3} style={{display: 'flex', alignItems: 'center', justifyContent: 'center'}}>
                <Link to="/about/adam">
                    <StaticImage src="../images/avatars/adam.png" style={{marginTop: '4rem', height: '50px', width: '50px', 'borderRadius': '50%'}} aspectRatio={1.2} height={50} />
                </Link>
            </Grid>
            <Grid item xs={3} style={{display: 'flex', alignItems: 'center', justifyContent: 'center'}}>
                <Link to="/about/cam">
                    <StaticImage src="../images/avatars/cam.jpg" style={{marginTop: '4rem', height: '50px', width: '50px', 'borderRadius': '50%'}} aspectRatio={1.1} height={50} />
                </Link>
            </Grid>
        </Grid>
    </div>
  )
}

export default AboutBammer